<template>
    <div class="QuotasView ViewWrapper">
        <clv-head-meta :title="$t('Service Quotas')"></clv-head-meta>
        <view-header>
            <h1>{{ $t('Service Quotas') }}</h1>
        </view-header>
        <view-container>

            <div class="mt-4"></div>

            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="gk-card">
                        <table class="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>{{ $t('Usage') }}</th>
                                <th>{{ $t('Limit') }}</th>
                            </tr>
                            </thead>
                            <tbody v-if="entityQluMap">
                            <!--
                            <tr>
                                <td>Users</td>
                                <td>{{ entityQluMap.user.usage }}</td>
                                <td>{{ entityQluMap.user.limit }}</td>
                            </tr>
                            -->
                            <tr>
                                <td>{{ $tc('entity Person', 2) }}</td>
                                <td>{{ entityQluMap.person.usage }}</td>
                                <td>{{ entityQluMap.person.limit }}</td>
                            </tr>
                            <tr>
                                <td>{{ $tc('entity Workspace', 2) }}</td>
                                <td>{{ entityQluMap.workspace.usage }}</td>
                                <td>{{ entityQluMap.workspace.limit }}</td>
                            </tr>
                            <tr>
                                <td>{{ $tc('entity Broadcaster', 2) }}</td>
                                <td>{{ entityQluMap.broadcaster.usage }}</td>
                                <td>{{ entityQluMap.broadcaster.limit }}</td>
                            </tr>
                            <tr>
                                <td>{{ $tc('entity Sender Email Address', 2) }}</td>
                                <td>{{ entityQluMap.senderEmailAddress.usage }}</td>
                                <td>{{ entityQluMap.senderEmailAddress.limit }}</td>
                            </tr>
                            <tr>
                                <td>{{ $tc('entity Email Template', 2) }}</td>
                                <td>{{ entityQluMap.emailTemplate.usage }}</td>
                                <td>{{ entityQluMap.emailTemplate.limit }}</td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <p class="mb-0">
                                        <router-link :to="{ name: 'limitations' }">{{ $t('Service Limitations') }}</router-link>
                                    </p>
                                    <p class="mb-0">
                                        <router-link :to="{ name: 'credits' }">{{ $t('Service Credits') }}</router-link>
                                    </p>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
/**
 * Service quotas view.
 *
 * @future : Add these?
 * - Email Verifications
 * - Email Campaigns
 * - SMS Campaigns
 *
 * @author Dimitris Gkoulis
 */
export default {
    name: 'QuotasView',
    data () {
        return {
            entityQluMap: null
        };
    },
    beforeMount () {
        // Get once. (it's better to retrieve quotas from system)
        this.entityQluMap = this.$store.getters['application/quotasEntityQluMap'];

        // Fetch all entityQlu (AGAIN).
        this.$store.dispatch('application/quotasGetAllEntityQlu').then((data) => {
            this.entityQluMap = data;
        }).catch((ignoredError) => {
            this.entityQluMap = null;
            // @future : Handle.
        });
    }
};
</script>
